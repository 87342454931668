import React from "react";
import { useState } from "react";
import "../Contact.css";
import { send } from "emailjs-com";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import ContactImage from "../images/interior.jpg";

const keyOne = process.env.REACT_APP_LOAN_INQ_SUBMIT_ONE
const keyTwo = process.env.REACT_APP_LOAN_INQ_SUBMIT_TWO
const keyThree = process.env.REACT_APP_LOAN_INQ_SUBMIT_THREE 

export default function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    response: "",
    textConsent: true
  });
  const [success, setSuccess] = useState(0)

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleConsent = (e) => {
    e.preventDefault();
    console.log(formData.textConsent)
    setFormData({
      ...formData,
      textConsent: e.target.checked
  })
}

  const handleFormSubmit = (e) => {
    e.preventDefault();
    send(
      keyOne,
      keyTwo,
      formData,
      keyThree
    )
      .then((response) => {
        setSuccess(1)
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        setSuccess(2)
        console.log("FAILED...", err);
      });
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <div className="contact-info">
          <h2>
            What are you waiting for? Let me help you buy your dream home!
          </h2>
          <p>
            I'm available to assist you with any mortgage needs. I am your
            dedicated mortgage resource for any and all questions you may have!
          </p>
          {/* <p>creatinsm</p> */}
        </div>
        <div className="contact-image-header">
          <img
            src={ContactImage}
            alt="buy that dream home!"
          />
        </div>
      </div>

    {success === 0 ? 
      <div className="form-container">
        {/* <form className="contact-form"> */}
        {/* <div className="form-info">
            <div className="form-block">
                <p>
                <i className="gg-phone"></i> (401) 639-4600
                </p>
            </div>
            <div className="form-block">
                <p>
                <i class="gg-pin"></i>
                3 Regency Plaza, Ste 23
                <br />
                Providence, Rhode Island <br />
                02903
                </p>
            </div>
            </div> */}
        <div className="form-contact">
          <div className="form-firstname">
            <label htmlFor="firstName">First name (required)</label>
            <input
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              placeholder="First name"
            />
          </div>

          <div className="form-lastname">
            <label htmlFor="lastName">Last name (required)</label>
            <input
              required
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last name"
            />
          </div>

          <div className="form-phone">
            <label htmlFor="phone">Phone number (required)</label>
            <input
              required
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone #"
            />
          </div>

          <div className="form-email">
            <label htmlFor="email">Email address (required)</label>
            <input
              required
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email address"
            />
          </div>

          <div className="form-message">
            <label htmlFor="response">Message (required)</label>
            <textarea
              required
              type="text"
              name="response"
              value={formData.response}
              onChange={handleChange}
              placeholder="What would you like to contact us regarding? E.g., reverse mortgage, refinance, etc."
            />
          </div>
          <FormGroup>
            <FormControlLabel 
              control={<Checkbox 
                        checked={formData.textConsent} 
                        onChange={handleConsent} 
                      />}
              // value={formData.textConsent} 
              // control={<Checkbox defaultChecked />} 
              label="Do you consent to be contacted via Text Message?" 
            />
          </FormGroup>
          <button className="slide contact-button " onClick={handleFormSubmit}>
            Let's get started!
          </button>
        </div>
      </div> : null}
      {success === 1 ? 
      <div className="contact-fail">
        <p>Thank you! We will be in contact shortly</p>
      </div>
      : null}
      {success === 2 ?
      <div className="contact-fail">
        <p>Sorry! There was an error! Please refresh the page and try again or send a text to 401-523-5947!</p>
      </div>
      : null}
      {/* </form> */}
      {/* </div> */}
    </div>
  );
}
